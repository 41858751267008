import routes from '@config/routes';
import { serverFetcher } from '@instance';
import { addQueryParam } from '@utils/add-query-param';
import { type EventResponse } from '@v2/types';
import { getRandomNumber } from '@v2/utils/random-number';
import { imagePathConcerts } from './config';
import { formatLeagueSlug, getVenueImages } from './getVenueImages';

type EventsByLocationResponse = {
  events: EventResponse[];
  total: number;
};

export interface Props {
  page?: number;
  perPage?: number;
  lat: number;
  lon: number;
  serverSide?: boolean;
  categoryName?: string;
  withVenueImages?: boolean;
  startDate?: string;
  endDate?: string;
  teamSlug?: string;
  onlyHomeGames?: boolean;
  opponentId?: number;
  concerts?: boolean;
  sports?: boolean;
}

export const getEventsByLocation = async ({
  lat,
  lon,
  page = 1,
  perPage = 50,
  concerts = false,
  sports = false,
  withVenueImages = false,
  startDate,
  endDate,
}: Props): Promise<EventsByLocationResponse> => {
  const res = await serverFetcher(
    `${routes.eventLocation}?${addQueryParam({ page, perPage, lat, lon, concerts, sports, startDate, endDate })}`,
    {
      method: 'GET',
    }
  );

  // add this temporary so it won't cause breaking changes on staging
  if (!withVenueImages) {
    return res;
  }
  const eventsList = res.events;

  if (concerts) {
    const formattedEvents = eventsList.map((event) => {
      const randomNumber = getRandomNumber();

      const image = `${imagePathConcerts}/rsc-${randomNumber}.jpg`;
      return {
        ...event,
        venue: { ...event?.venue, image },
      };
    });

    return { events: formattedEvents, total: eventsList.length };
  }

  const venueImages = await getVenueImages(eventsList);

  const formattedEvents = eventsList.map((event) => {
    const venueImage = venueImages.find(
      (item) =>
        item.venueId === event?.venue?.id &&
        item.league === formatLeagueSlug(event?.category?.slug)
    );
    return {
      ...event,
      venue: { ...event?.venue, image: venueImage?.imagePath },
    };
  });

  return {
    events: formattedEvents,
    total: eventsList.length,
  };
};
