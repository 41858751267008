import { SVG, SVGComponentProps } from '@v2/ui';
import { FC } from 'react';

export const OnlineTicket2: FC<SVGComponentProps> = ({ ...props }) => {
  return (
    <SVG size="48" viewBox="0 0 48 48" stroke="none" {...props}>
      <svg
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_7926_17671)">
          <path
            d="M31.223 19.7812H20.5996"
            stroke="currentColor"
            stroke-width="2"
            stroke-miterlimit="2.6131"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M31.223 24H20.5996V28.2188H31.223V24Z"
            stroke="currentColor"
            stroke-width="2"
            stroke-miterlimit="22.9256"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M37.4336 32.4375V15.5625"
            stroke="currentColor"
            stroke-width="2"
            stroke-miterlimit="2.6131"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M28.2168 33.375V44.6345C28.2168 45.9181 27.197 46.9688 25.9511 46.9688H3.29495C2.04883 46.9688 1.0293 45.9183 1.0293 44.6345V3.36544C1.0293 2.08162 2.04883 1.03125 3.29495 1.03125H25.9511C27.1972 1.03125 28.2168 2.08228 28.2168 3.36544V14.625"
            stroke="#1D2939"
            stroke-width="2"
            stroke-miterlimit="22.9256"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M21.6543 1.5V3.375C21.6543 4.66406 20.5996 5.71875 19.3105 5.71875H9.93555C8.64658 5.71875 7.5918 4.66406 7.5918 3.375V1.5"
            stroke="#1D2939"
            stroke-width="2"
            stroke-miterlimit="22.9256"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M16.9668 42.2812H12.2793"
            stroke="#1D2939"
            stroke-width="2"
            stroke-miterlimit="22.9256"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M46.9668 15.0938H12.2793V17.6719C13.4442 17.6719 14.3887 18.6163 14.3887 19.7812C14.3887 20.9462 13.4442 21.8906 12.2793 21.8906V26.1093C13.4442 26.1093 14.3887 27.0537 14.3887 28.2187C14.3887 29.3836 13.4442 30.328 12.2793 30.328V32.9062H46.9668V30.328C45.8019 30.328 44.8574 29.3836 44.8574 28.2187C44.8574 27.0537 45.8019 26.1093 46.9668 26.1093V21.8906C45.8019 21.8906 44.8574 20.9462 44.8574 19.7812C44.8574 18.6163 45.8019 17.6719 46.9668 17.6719V15.0938Z"
            stroke="#1D2939"
            stroke-width="2"
            stroke-miterlimit="2.6131"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_7926_17671">
            <rect width="48" height="48" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SVG>
  );
};

export const Calendar1: FC<SVGComponentProps> = ({ ...props }) => {
  return (
    <SVG size="48" viewBox="0 0 48 48" stroke="none" {...props}>
      <g clip-path="url(#clip0_7926_17686)">
        <path
          d="M39.8493 32.0845H37.2701C36.6255 32.0845 36.1029 31.562 36.1029 30.9173V28.3381C36.1029 27.6935 36.6255 27.1709 37.2701 27.1709H39.8493C40.494 27.1709 41.0166 27.6935 41.0166 28.3381V30.9173C41.0166 31.562 40.494 32.0845 39.8493 32.0845Z"
          stroke="#1D2939"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M39.8493 40.8892H37.2701C36.6255 40.8892 36.1029 40.3667 36.1029 39.722V37.1428C36.1029 36.4982 36.6255 35.9756 37.2701 35.9756H39.8493C40.494 35.9756 41.0166 36.4982 41.0166 37.1428V39.722C41.0166 40.3667 40.494 40.8892 39.8493 40.8892Z"
          stroke="#1D2939"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M10.9987 37.3359L8.80598 39.5287L7.85681 38.5795"
          stroke="currentColor"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M20.7096 37.3359L18.5169 39.5287L17.5677 38.5795"
          stroke="currentColor"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M30.4205 37.3359L28.2279 39.5287L27.2787 38.5795"
          stroke="currentColor"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M10.7165 23.0162H8.13731C7.49268 23.0162 6.97009 22.4936 6.97009 21.849V19.2698C6.97009 18.6251 7.49268 18.1025 8.13731 18.1025H10.7165C11.3612 18.1025 11.8837 18.6251 11.8837 19.2698V21.849C11.8837 22.4936 11.3612 23.0162 10.7165 23.0162Z"
          stroke="#1D2939"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M20.4275 23.0162H17.8483C17.2036 23.0162 16.681 22.4936 16.681 21.849V19.2698C16.681 18.6251 17.2036 18.1025 17.8483 18.1025H20.4275C21.0721 18.1025 21.5947 18.6251 21.5947 19.2698V21.849C21.5946 22.4936 21.0721 23.0162 20.4275 23.0162Z"
          stroke="#1D2939"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M30.1403 23.0162H27.5611C26.9165 23.0162 26.3939 22.4936 26.3939 21.849V19.2698C26.3939 18.6251 26.9165 18.1025 27.5611 18.1025H30.1403C30.785 18.1025 31.3076 18.6251 31.3076 19.2698V21.849C31.3076 22.4936 30.785 23.0162 30.1403 23.0162Z"
          stroke="#1D2939"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M39.8493 23.0162H37.2701C36.6255 23.0162 36.1029 22.4936 36.1029 21.849V19.2698C36.1029 18.6251 36.6255 18.1025 37.2701 18.1025H39.8493C40.494 18.1025 41.0166 18.6251 41.0166 19.2698V21.849C41.0166 22.4936 40.494 23.0162 39.8493 23.0162Z"
          stroke="#1D2939"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M10.7165 32.0845H8.13731C7.49268 32.0845 6.97009 31.562 6.97009 30.9173V28.3381C6.97009 27.6935 7.49268 27.1709 8.13731 27.1709H10.7165C11.3612 27.1709 11.8837 27.6935 11.8837 28.3381V30.9173C11.8837 31.562 11.3612 32.0845 10.7165 32.0845Z"
          stroke="#1D2939"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M20.4275 32.0845H17.8483C17.2036 32.0845 16.681 31.562 16.681 30.9173V28.3381C16.681 27.6935 17.2036 27.1709 17.8483 27.1709H20.4275C21.0721 27.1709 21.5947 27.6935 21.5947 28.3381V30.9173C21.5946 31.562 21.0721 32.0845 20.4275 32.0845Z"
          stroke="#1D2939"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M30.1403 32.0845H27.5611C26.9165 32.0845 26.3939 31.562 26.3939 30.9173V28.3381C26.3939 27.6935 26.9165 27.1709 27.5611 27.1709H30.1403C30.785 27.1709 31.3076 27.6935 31.3076 28.3381V30.9173C31.3076 31.562 30.785 32.0845 30.1403 32.0845Z"
          stroke="#1D2939"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M1.72595 13.5791H46.9369"
          stroke="#1D2939"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M47.3043 13.5792V41.677C47.3043 43.74 45.6318 45.4124 43.5689 45.4124H5.09417C3.0312 45.4124 1.35876 43.74 1.35876 41.677V13.5792"
          stroke="#1D2939"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M47.3043 13.5795V9.38189C47.3043 7.31892 45.6318 5.64648 43.5689 5.64648H5.09417C3.0312 5.64648 1.35876 7.31892 1.35876 9.38189V13.5795"
          stroke="#1D2939"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M9.224 2.58789V8.6612"
          stroke="#1D2939"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M16.681 2.58789V8.6612"
          stroke="#1D2939"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M24.14 2.58789V8.6612"
          stroke="#1D2939"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M31.597 2.58789V8.6612"
          stroke="#1D2939"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M39.0541 2.58789V8.6612"
          stroke="#1D2939"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_7926_17686">
          <rect
            width="48"
            height="48"
            fill="white"
            transform="translate(0.333374)"
          />
        </clipPath>
      </defs>
    </SVG>
  );
};
export const Suitcase: FC<SVGComponentProps> = ({ ...props }) => {
  return (
    <SVG size="48" viewBox="0 0 48 48" stroke="none" {...props}>
      <svg
        width="49"
        height="48"
        viewBox="0 0 49 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_7926_17712)">
          <path
            d="M33.2488 21.1406V32.6726"
            stroke="currentColor"
            stroke-width="2"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M41.9207 21.1406V32.6726"
            stroke="currentColor"
            stroke-width="2"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M33.1721 44.7708C33.535 43.234 32.5834 41.6941 31.0466 41.3312C29.5098 40.9683 27.9699 41.9199 27.607 43.4567C27.2441 44.9935 28.1957 46.5334 29.7325 46.8963C31.2693 47.2592 32.8092 46.3075 33.1721 44.7708Z"
            stroke="currentColor"
            stroke-width="2"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M47.5627 44.7708C47.9256 43.234 46.974 41.6941 45.4372 41.3312C43.9005 40.9683 42.3605 41.9199 41.9976 43.4567C41.6347 44.9935 42.5864 46.5334 44.1231 46.8963C45.6599 47.2592 47.1999 46.3075 47.5627 44.7708Z"
            stroke="currentColor"
            stroke-width="2"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M7.42065 24V41.2504"
            stroke="currentColor"
            stroke-width="2"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M21.8113 24V41.2504"
            stroke="currentColor"
            stroke-width="2"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M27.5298 41.2504H1.7019V24H27.5298"
            stroke="#1D2939"
            stroke-width="2"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M27.53 41.2505V12.5633H47.6396V41.2505H27.53Z"
            stroke="#1D2939"
            stroke-width="2"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M18.9528 18.2812H10.28V23.9996H18.9528V18.2812Z"
            stroke="#1D2939"
            stroke-width="2"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M41.9207 1.48326V12.5629"
            stroke="#1D2939"
            stroke-width="2"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M33.2488 12.5632V1.03125"
            stroke="#1D2939"
            stroke-width="2"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M30.3894 1.03125H44.7806"
            stroke="#1D2939"
            stroke-width="2"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_7926_17712">
            <rect
              width="48"
              height="48"
              fill="white"
              transform="translate(0.666748)"
            />
          </clipPath>
        </defs>
      </svg>
    </SVG>
  );
};
