import { AWSLocationResponse } from '@v2/types';
import { getCityPageData } from '@v2/views/city/services/getCityPageData';
import { useCallback, useEffect, useState } from 'react';

interface UseLocationManagementProps {
  defaultUserLocation?: string;
  initialLoading?: boolean;
}

export const useLocationManagement = ({
  defaultUserLocation,
  initialLoading = false,
}: UseLocationManagementProps) => {
  const [locationCityName, setLocationCityName] = useState<string>();
  const [selectedLocation, setSelectedLocation] =
    useState<AWSLocationResponse>();
  const [loading, setLoading] = useState(initialLoading);

  const handleGetLocation = async (loc: string) => {
    const cityData = await getCityPageData(loc);

    if (cityData) {
      const item = { ...cityData, Text: cityData.Municipality };
      setSelectedLocation(item);
      setLocationCityName(`${cityData.Municipality},`);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (defaultUserLocation) {
      setLoading(true);
      handleGetLocation(defaultUserLocation);
    }
  }, [defaultUserLocation]);

  const onLocationChange = useCallback((location: AWSLocationResponse) => {
    setLocationCityName(`${location.Text} ${location?.Region ?? ''}`);
    setSelectedLocation(location);
  }, []);

  return {
    locationCityName,
    selectedLocation,
    loading,
    onLocationChange,
  };
};
