'use client';

import { useThemeConfigStore } from '@store/theme-config-store';
import { Calendar1, OnlineTicket2, Suitcase } from './icons';
import { useBetterMediaQuery } from '@hooks/use-ssr-media-query';

const usageSteps = [
  {
    icon: OnlineTicket2,
    title: 'Choose an event',
    description:
      'Look up live events in any city or venue to begin organizing the perfect trip.',
  },
  {
    icon: Calendar1,
    title: 'Build a package',
    description:
      'Get the greatest accommodations, flights and event tickets at affordable prices.',
  },
  {
    icon: Suitcase,
    title: 'Book your trip',
    description:
      'No more scrambling to plan trips. Keep track of your entire itinerary in a single location.',
  },
];

export const InstructionsSection = () => {
  const scrollToUp = () => {
    document.querySelector('header')?.scrollIntoView({ behavior: 'smooth' });
  };

  const primaryColor = useThemeConfigStore((state) => state.primaryColor);
  const isTablet = useBetterMediaQuery('(min-width: 768px)');

  return (
    <section className="flex w-full max-w-7xl flex-col border-gray-300 md:flex-row">
      <div className="flex w-full max-w-2xl flex-col items-start justify-center">
        <h1 className="md:bleading-[48px] mb-3 w-full max-w-lg text-xl font-bold -tracking--0.72 md:mb-5 md:text-4xl">
          Your go-to solution for perfectly planned getaways
        </h1>
        <p className="mb-6 text-sm font-medium text-gray-500 md:mb-10 md:text-lg">
          Find the best deals on hotel & ticket packages.
        </p>
        <button
          onClick={scrollToUp}
          type="button"
          className={`flex h-10 w-28 cursor-pointer items-center justify-center rounded-lg font-semibold text-white md:h-12 md:w-32`}
          style={{ backgroundColor: primaryColor }}
        >
          Search now
        </button>
      </div>
      <div className="mt-10 flex w-full max-w-2xl flex-col gap-10 md:mt-0 md:flex-row">
        {usageSteps.map((step) => {
          const Svg = step.icon;

          return (
            <div key={step.title} className="w-full md:max-w-44">
              <Svg
                className="text-[#175CD3]"
                stroke="#175CD3"
                size={isTablet ? '32' : '48'}
              />
              <h3 className="text-md mb-2 mt-3 font-bold text-gray-800 md:mb-4 md:mt-5 md:text-xl">
                {step.title}
              </h3>
              <p className="tet-sm font-medium text-gray-500 md:text-base">
                {step.description}
              </p>
            </div>
          );
        })}
      </div>
    </section>
  );
};
