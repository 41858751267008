'use client';
import { useLocationManagement } from '@hooks/useLocationManagement';
import { EventsCarouselSkeleton } from '@v2/components/EventsCarouselSkeleton/EventsCarouselSkeleton';
import { useSession } from 'next-auth/react';
import { Suspense, useMemo } from 'react';
import { EventLocationDropdown } from './EventLocationDropdown';
import { EventsCarousel } from './EventsCarousel';

interface Props {
  initialLocationEvents?: any;
  dropdownPrimaryColor?: string;
}

export const EventsCarouselByLocation = ({
  initialLocationEvents,
  dropdownPrimaryColor,
}: Props) => {
  const serverEvents = useMemo(() => initialLocationEvents, []);
  const { data } = useSession();

  const { locationCityName, selectedLocation, loading, onLocationChange } =
    useLocationManagement({
      defaultUserLocation: data?.user?.location,
    });

  if (loading) return <EventsCarouselSkeleton withTitle />;
  return (
    <div className="flex flex-col">
      <EventLocationDropdown
        selectedLocation={selectedLocation}
        onLocationChange={onLocationChange}
        primaryColor={dropdownPrimaryColor}
      />

      <Suspense fallback={<EventsCarouselSkeleton withTitle={false} />}>
        <EventsCarousel
          initialEvents={serverEvents}
          location={locationCityName}
        />
      </Suspense>
    </div>
  );
};
